import Vue from 'vue'

export default Vue.extend({
  methods: {
    formattedPhoneNumber (phone: string) {
      if (!phone) {
        return null
      }

      const match = phone
        .toString()
        .replace(/ /g, '')
        .match(/^(\d{3})(\d{3})(\d{4})$/)

      if (!match) {
        return null
      }

      return `(${match[1]}) ${match[2]}-${match[3]}`
    },
    capitalize (word: string) {
      if (typeof word !== 'string') return ''
      word = word.toLocaleLowerCase()
      return word.charAt(0).toUpperCase() + word.slice(1)
    }
  }
})
