<template>
  <div class="inline-container">
    <div v-if="!isEditing" class="inline-text" :class="{'is-disable': disable}" @click="displaySelect()">
      {{ displayValue }}
    </div>
    <div v-else ref="select" class="form-inline">
      <div class="form-group w-85">
        <select v-model="value" class="form-control w-100">
          <option v-for="item in list" :key="item.value" :value="item.value" >{{ item.label }}</option>
        </select>
      </div>
      <button class="btn ml-2" @click.prevent="submit">
        <fa-icon class="text-success" :icon="['fas', 'check']"></fa-icon>
      </button>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
const { bind, unbind } = vClickOutside.directive

export default {
  watch: {
    defaultValue: {
      immediate: true,
      handler (value) {
        this.value = value
      }
    }
  },
  props: {
    displayValue: {
      type: String,
      required: true,
      default: ''
    },
    defaultValue: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true,
      default: ''
    },
    list: {
      type: Array,
      required: true,
      default: () => {
        return []
      }
    },
    item: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    callBack: {
      type: Function,
      required: false,
      default: () => {
        return {}
      }
    },
    disable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isEditing: false,
      value: ''
    }
  },
  methods: {
    displaySelect () {
      if (this.disable) {
        return
      }

      this.isEditing = !this.isEditing

      this.$nextTick(() => {
        if (this.isEditing) {
          bind(this.$refs.select, { value: this.reset })
        } else {
          unbind(this.$refs.select)
        }
      })
    },
    reset () {
      this.isEditing = false
      this.value = this.defaultValue
    },
    submit () {
      this.callBack(this.value, this.item)
      this.reset()
    }
  },
  mounted () {
    document.addEventListener('keyup', (evt) => {
      if (evt.code === 'Escape') {
        this.reset()
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.inline-container {
  .inline-text {
    padding: 6px 0 6px 6px;
    cursor: pointer;

    &:hover {
      padding: 5px 0 5px 4px;
      border: 1px solid rgba(204, 204, 204, 0.829);
    }

    &.is-disable {
      cursor: no-drop !important;
    }
  }
  .w-85 {
    width: 85% !important;
  }
}
</style>
