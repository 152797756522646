<template>
  <div class="position-relative d-block user-select-none">
    <b-form-checkbox :checked="checked" @click.native.prevent="handleChange" switch :size="size">
      <label v-if="label">{{ label }}</label>
    </b-form-checkbox>
  </div>
</template>

<script>

export default {
  props: {
    checked: {
      type: Boolean,
      required: true,
      default: false
    },
    label: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      validator: value => ['sm', 'lg'].includes(value)
    },
    callBack: {
      type: Function,
      required: true,
      default: () => {
        return {}
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    async handleChange () {
      await this.$nextTick()
      this.callBack(!this.checked)
    }
  }
}
</script>
