import { StoreObject } from '@/types/stores'

export const getStoreRegistrationStatus = (store: StoreObject | undefined) => {
  switch (store?.registrationStatus) {
    case 'legalProfile':
      return 'Profil légal'
    case 'bankAccount':
      return 'Compte bancaire'
    case 'taxes':
      return 'Taxes'
    case 'publicProfile':
      return 'Profil public'
    case 'completed':
      return 'Complété'
    default:
      return ''
  }
}
